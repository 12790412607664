html, body
{
    width: 100%;
    height: 100%;

    margin: 0 0;
    padding: 0 0;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  opacity: .45;
}

#root {
  height: 100%;
  font-family: "Poppins-Regular";
}
.verticalDivider {
  background-color:#ECEFF1;
  height:80%;
  width:1px;
}

:focus { outline: none; }

.slick-prev:before, .slick-next:before{
  font-size: 40px
}

.slick-prev {
  left: -100px
}

.slick-next {
  right: -100px
}

.keyboard
.keyboard-spaceButton{
  display:flex;
}
.keyboard-text {
  pointer-events:none;
  width:100%;
}

.internal-keyboard .keyboard-text{
  min-width:1624px;
  width:auto;
}

.keyboard-numeric {
  pointer-events:none;
  width:100%;
}
.keyboard-email {
  pointer-events:none;
  width:100%;
}
.keyboard-promocode {
  pointer-events:none;
  width:100%;
}
.keyboard {
  pointer-events:auto;
}

.internal-keyboard .keyboard{
  width: 100%;
	min-width: 1624px;
	background: #dadada;
  box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.5);
  right: 0;
  bottom: 0;
}

.internal-keyboard .keyboard-button {
  flex-grow: 1;
  text-transform: uppercase;
}

.internal-keyboard .keyboard-button.keyboard-submit-button{
  flex-grow: 0;
}

.keyboard-numeric .keyboard {
	background: #dadada;
	box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.5);
}


.keyboard-numeric .keyboard-row:first-of-type {
  display:none;
}

.keyboard-numeric .keyboard-row {
	display: flex;
  justify-content: center;
}

.keyboard-button{
  background-color:#FFFFFF;
  color: #000000;
  font-size: 24px;
  box-shadow: 0px 1px rgba(0,0,0,0.3);
  font-family: "Poppins-Regular";
}

.keyboard-submit-button{
  background-color: #FFD938;
  border-color: #FFD938;
  flex-grow: 0;
}

.keyboard-symbolButton,
.keyboard-backspaceButton,
.keyboard-shiftButton{
	background-color: #78909C;
  color: #FFFFFF;
}

.keyboard-numeric
.keyboard-row
.keyboard-symbolButton{
	display: none;
}

.internal-keyboard .keyboard-symbolButton {
  display:none;
}

.internal-keyboard .keyboard-shiftButton {
  display: none;
}

.keyboard-numeric
.keyboard-row
.keyboard-shiftButton{
	display: none;
}

.keyboard-numeric
.keyboard-spaceButton{
	display: none;
}

.keyboard-numeric
.keyboard-languageButton{
	display: none;
}

.keyboard-utilButton {
	flex-grow: 2;
	max-width: 55px;
}

.keyboard-additionalButton {
	flex-basis: 128px;
}

.keyboard-numeric
.keyboard-submit-button {
	flex-basis: 5.5em;
}

.App{
  height: 100%;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}
.App-external {
  text-align: center;
  height: 100%;
}

.flag-select .country-flag,
.flag-select .selected--flag--option,
.flag-select .country-flag .country-label {
  font-size: 24px;
}

@-webkit-keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url(/static/media/Poppins-Regular.d709511c.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins-Bold';
  src: url(/static/media/Poppins-Bold.12413cac.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins-SemiBold';
  src: url(/static/media/Poppins-SemiBold.4ca619e8.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins-Medium';
  src: url(/static/media/Poppins-Medium.c7b158e3.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins-Light';
  src: url(/static/media/Poppins-Light.32b18121.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
.countdown-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow-y: hidden;
  max-height: 100px; /* approximate max height */
}
.countdown {

	transition-property: all;
  opacity: 1;
  transition: opacity 2s ease-in;

}
.countdown.hidden {
  opacity: 0;
  transition: opacity 0.1s ease-in;
}
.jabbrrbox-screensaver-img, .jabbrrbox-screensaver-container {
  width: 475px;
  height: 87px;
}
.flash{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 9;
    background-color:#fff;
    overflow-y: hidden;
 }
.x {
  -webkit-animation: x 13s linear infinite alternate;
          animation: x 13s linear infinite alternate;
}

.y {
  -webkit-animation: y 7s linear infinite alternate;
          animation: y 7s linear infinite alternate;
}
.preset-display {
  display:flex;
  align-items: center;
  width: 0;
  height: 0;
  opacity: 0;
}
.preset-display.active {
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 2s ease-in;
}
.not-preset-display.active {
  display: none;
}

@-webkit-keyframes x {
  100% {
    transform: translateX( calc(100vw - 475px));
  }
}

@keyframes x {
  100% {
    transform: translateX( calc(100vw - 475px));
  }
}

@-webkit-keyframes y {
  100% {
    transform: translateY( calc(100vh - 87px));
  }
}

@keyframes y {
  100% {
    transform: translateY( calc(100vh - 87px));
  }
}
.bounce {
  -webkit-animation: bouncePlus 2s infinite;
          animation: bouncePlus 2s infinite;
}
.bounce2{
	-webkit-animation: bounceMinus 2s infinite;
	        animation: bounceMinus 2s infinite;
}
@-webkit-keyframes bouncePlus{
	0%, 20%, 50%, 80%, 100% {
    transform:translateX(0);
  }
	40% {
    transform:translateX(30px);
  }
	60% {
    transform:translateX(15px);
  }
}
@keyframes bouncePlus{
	0%, 20%, 50%, 80%, 100% {
    transform:translateX(0);
  }
	40% {
    transform:translateX(30px);
  }
	60% {
    transform:translateX(15px);
  }
}
@-webkit-keyframes bounceMinus{
	0%, 20%, 50%, 80%, 100% {
    transform:translateX(0);
  }
	40% {
    transform:translateX(-30px);
  }
	60% {
    transform:translateX(-15px);
  }
}
@keyframes bounceMinus{
	0%, 20%, 50%, 80%, 100% {
    transform:translateX(0);
  }
	40% {
    transform:translateX(-30px);
  }
	60% {
    transform:translateX(-15px);
  }
}

@-webkit-keyframes fill {
  to {
    background-size: 100% 0;
  }
}

@keyframes fill {
  to {
    background-size: 100% 0;
  }
}

.cnh_holding button {
  background: linear-gradient( #ECEFF1, #ECEFF1) rgb(255,215,235) no-repeat 0 0;
  mix-blend-mode: multiply;
  background-size: 100% 100%;
  -webkit-animation: fill 3s forwards;
  animation: fill 3s forwards;
}
iframe {
  border: none !important;
}

